/**
 * Form Page Component
 *
 * Description:
 *
 *
 *
 * Components Used:
 *
 *
 * Author:
 *  - Ayaz Shah (ayaz.shah@mongodb.com)
 *  - Adlai Gordon (adlai.gordon@mongodb.com)
 *  - Federico Inserra (federico.inserra@mongodb.com)
 */

import Layout from "../../components/Layout/Layout";
import { Col, Row } from "react-bootstrap";
import { Option, Select } from "@leafygreen-ui/select";
import { RadioBox, RadioBoxGroup } from '@leafygreen-ui/radio-box-group';
import MultiSelect from 'react-select'
import { Combobox, ComboboxOption } from "@leafygreen-ui/combobox";
import TextInput from "@leafygreen-ui/text-input";
import TextArea from "@leafygreen-ui/text-area";
import React, { useEffect, useState, useContext } from "react";
import FormFooter from "@leafygreen-ui/form-footer";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { SearchInput, SearchResult } from "@leafygreen-ui/search-input";
import { H1, Body, Label, Link, Description } from "@leafygreen-ui/typography";
import Badge from "@leafygreen-ui/badge";
import Banner from "@leafygreen-ui/banner";
import addDays from "date-fns/addDays";
import { AuthContext } from "../../realmApp/AuthProvider";
import { useNavigate } from "react-router-dom";
import Toast from "@leafygreen-ui/toast";
import { app } from "../../realmApp/realmApp";
import Checkbox from "@leafygreen-ui/checkbox";
import * as Realm from "realm-web";
import { useOktaAuth } from "@okta/okta-react";
import "../forms/form.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import TextareaAutosize from 'react-textarea-autosize';
import { sub } from "date-fns";




interface Props {
  isProtected: boolean;
}

export const SpecialistForm = ({ isProtected }: Props) => {
  
  // Get the card paramter "cardLink" passed from home page
  const { card } = useParams<{ card: string }>();

  /* Set if SFDC Account or Opportunity is Required or not
  */
  // Sets default state of required
  const [accountFieldRequired, setAccountFieldRequired] = useState(true);
  const [accountFieldLabel, setAccountFieldLabel] = useState<string>("Account Name *");
  const [opportunityFieldRequired, setOpportunityFieldRequired] = useState(true);
  const [opportunityFieldLabel, setOpportunityFieldLabel] = useState<string>("Salesforce Opportunity Name *");

  // Call this function if not required 
  const setAccountOppFieldsNotRequired = () => {
    setAccountFieldRequired(false);
    setAccountFieldLabel("Account Name");
    setOpportunityFieldRequired(false);
    setOpportunityFieldLabel("Salesforce Opportunity Name");
  };
  
   /*
    Set the default values for each field
    Also need to create a hidden switch above
  */

    const [salesSegment, setSalesSegment] = useState<string>("");

    const [title, setTitle] = useState<string>("");
  
  const [routeValue, setRouteValue] = useState("");
  const [oppsList, setOppsList] = useState([]);
  const [selectedOpp, setSelectedOpp] = useState("");
  const [disableOpp, setDisableOpp] = useState(true);
  const [accountId, setAccountId] = useState("");
  const [accountInfo, setAccountInfo] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [subjectValue, setSubjectValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [priority, setPriority] = useState("Normal")
  const [requesterRegion, setRequesterRegion] = useState("")
  const [realmException, setRealmException] = useState(false)
  const [scProductArea, setScProductArea] = useState("")
  const setScProductAreaWrap = (productArea) => {
    setScProductArea(productArea)
    setSubmitButtonDisabled((productArea == "Realm" ))
    setRealmException((productArea == "Realm" ))
  }
  const [scRequestType, setScRequestType] = useState("")
  const [timezoneValue, setTimezoneValue] = useState("");
  
  const [extraEmailsValue, setExtraEmailsValue] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [emailCache, setEmailCache] = useState({});
  
  const [primarySAList, setPrimarySAList] = useState([]);
  const [primarySAValue, setPrimarySAValue] = useState("");
  const [saCache, setSaCache] = useState({})

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  


  const [userProfile, setUserProfile] = useState<{
    data: {
      firstName: string;
      lastName: string;
      email: string;
      name: string;
    };
    identities: any[];
    type: string;
  } | null>(null);

  const [open, setOpen] = useState(false);
  const [validationToastOpen, setValidationToastOpen] = useState(false);
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [warningToastOpen, setWarningToastOpen] = useState(false);
  const [progressToastOpen, setProgressToastOpen] = useState(false);
  const [ticketId, setTicketID] = useState("");
  const [ticketWarning, setTicketWarning] = useState("");


  // Validation Fields
  const [ticketValidation, setTicketValidation] = useState([]);
  const [isAccountSelected, setIsAccountSelected] = useState(false);
  const [isCcSelected, setIsCcSelected] = useState(false);
  const [isprimarySASelected, setIsprimarySASelected] = useState(false);
  const navigate = useNavigate();

  // MAAP
  const [SCProductVisibility, setSCProductVisibility] = useState(true);
  const [SCRequestVisibility, setSCRequestVisibility] = useState(true);

  // MAAP Engagement
  const handleMultiChange = (questionId, option) => {
    console.log('Selected value:', option);
    setMaapEngagementQuestions(prevQuestions => ({
      ...prevQuestions,
      [questionId]: { "q" : prevQuestions[questionId]["q"], "a" : option.map(op => op.value), "checkboxs" : prevQuestions[questionId]["checkboxs"] }
    }));
  };

  const [maapEngagementQuestions, setMaapEngagementQuestions] = useState({
    1 : {"a": "", "q" : "If you have a customer that is a good fit for MAAP engagement, please state the stage of the customer and complete the rest of the info in this form *"},
    2 : {"a": "", "q" : "Engagement Manager Name *"},
    3 : {"a": "", "q" : "Customer Status *"},
    4 : {"a": [], "q" : "Please select all of the Cloud & AI Partners that the custopmer uses or has shown interest in *", "checkboxs": [
     { value: 'AWS', label: 'AWS' },
     { value: 'Azure', label: 'Azure' },
     { value: 'GCP', label: 'GCP' },
     { value: 'Anthropic', label: 'Anthropic' },
     { value: 'Cohere', label: 'Cohere' },
     { value: 'Nomic', label: 'Nomic' },
     { value: 'Anyscale', label: 'Anyscale' },
     { value: 'Fireworks AI', label: 'Fireworks AI' },
     { value: 'Together AI', label: 'Together AI' },
     { value: 'Credal.ai', label: 'Credal.ai' },
     { value: 'Langchain', label: 'Langchain' },
     { value: 'LlamaIndex', label: 'LlamaIndex' },
     { value: 'Arcee', label: 'Arcee' },
     { value: 'None', label: 'None' },
     { value: 'Other', label: 'Other' }]
    },
    5 : {"a": [], "q" : "REMOVED Q", "checkboxs": []},
    6 : {"a": "", "q" : "Based on the urgency of this opportunity, is there a specific timeline set for next steps of customer engagement? *"},
    7 : {"a": "", "q" : "What is the customer using vector search/embeddings for in their application? *"},
    8 : {"a": "", "q" : "Where is the data needed for this application? *"},
    9 : {"a": "", "q" : "How does the customer see the technical level of their staff? How much help do they need to build this? *"},
    10 : {"a": "", "q" : "What is your application's main purpose and who are its end users? *"},
    11 : {"a": "", "q" : "Which business unit/team owns this project and what's driving the initiative? *"},
    12 : {"a": "", "q" : "Please link your Discovery Capture Sheet and any other primary discovery documents *"},
    13 : {"a": "", "q" : "Based on the urgency of this request, is there a specific timeline set for next steps of customer engagement? *"},
    14 : {"a": "", "q" : "Ideally, when should this application be released? *"},
  });

  // notice the lack of S at the end
  const setMaapEngagementQuestion = (questionId, answer) => {
    setMaapEngagementQuestions(prevQuestions => ({
      ...prevQuestions,
      [questionId]: { "q" : prevQuestions[questionId]["q"], "a" : answer }
    }));
  };

  // MAAP Feedback
  const [maapFeedbackQuestions, setMaapFeedbackQuestions] = useState({
    1 : {"a": "", "q" : "If you have general feedback on MAAP (i.e. its rollout, its messaging, its enablement, its processes, etc.) and you may submit the form as is", "qq" : "General Feedback"},
    2 : {"a": "", "q" : "If you have feedback specific to the library of architectures of MAAP please share here, and you may submit the form as is", "qq" : "Architecture comment"},
    3 : {"a": "", "q" : "If you have a general question on MAAP that requires clarification, please enter it here and you may submit the form as is", "qq" : "Question"}
  });

  // notice the lack of S at the end
  const setMaapFeedbackQuestion = (questionId, answer) => {
    setMaapFeedbackQuestions(prevQuestions => ({
      ...prevQuestions,
      [questionId]: { "q" : prevQuestions[questionId]["q"], "a" : answer, "qq" : prevQuestions[questionId]["qq"]}
    }));
  };


  // Get the user from the context
  const [user, setUser] = useState(null);
  const { authState, oktaAuth } = useOktaAuth();

  // Search Sizing questions
  const [searchSizingQuestions, setSearchSizingQuestions] = useState({
    1 : {"a": "", "q" : "please share link to specialist sizing calculator found above"}
  }); 
  /*
  const [searchSizingQuestions, setSearchSizingQuestions] = useState({
    1 : {"a": "", "q" : "How many queries do you anticipate per minute at peak load?"},
    2 : {"a": "", "q" : "Do you intend to use subsequent stages of the aggregation pipelines with search? If so, which stages?"},
    3 : {"a": "", "q" : "Will your search users need typeahead/autocomplete/partial matching features?"},
    4 : {"a": "", "q" : "How many documents are in the collection where you’d like to add a search index?"},
    5 : {"a": "", "q" : "How many text fields need to be searchable per doc?"},
    6 : {"a": "", "q" : "What is the approximate size of text in each search field?"},
    7 : {"a": "", "q" : "Do you need to search within arrays of embedded fields? How many subdocuments per document and how many searchable fields per subdocument?"},
    8 : {"a": "", "q" : "How many fields will be enabled for faceting?"},
    9 : {"a": "", "q" : "Will your users need to sort search results by anything other than relevancy? Which fields will they sort by?"},
    10 : {"a": "", "q" : "What is the approximate total size of data in each of the facet and sort fields mentioned above?"},
    11 : {"a": "", "q" : "Do you need to support synonyms? Approximately how many?"},
    12 : {"a": "", "q" : "Do you need to search across collections or indexes?"}
  }); */

  // notice the lack of S at the end
  const setSearchSizingQuestion = (questionId, answer) => {
    setSearchSizingQuestions(prevQuestions => ({
      ...prevQuestions,
      [questionId]: { "q" : prevQuestions[questionId]["q"], "a" : answer }
    }));
  };

    // Search Sizing questions
    const [realmMigrationSizingQuestion, setRealmMigrationSizingQuestions] = useState({
      1 : {"a": "", "q" : "What is the customer's current ARR spend on Realm ADS?"},
      2 : {"a": "", "q" : "What is the total revenue at risk in the account due to the deprecations?"}
    });
  
    // notice the lack of S at the end
    const setRealmMigrationSizingQuestion = (questionId, answer) => {
      setRealmMigrationSizingQuestions(prevQuestions => ({
        ...prevQuestions,
        [questionId]: { "q" : prevQuestions[questionId]["q"], "a" : answer }
      }));
    };

    // Vector Sizing questions
    const [vectorSizingQuestions, setVectorSizingQuestions] = useState({
      1 : {"a": "", "q" : "please share link to specialist sizing calculator found above"}
    });
    /* 
    const [vectorSizingQuestions, setVectorSizingQuestions] = useState({
      1 : {"a": "", "q" : "How many documents (vectors) do you intend on storing?"},
      2 : {"a": "", "q" : "How many dimensions does each vector have?"},
      3 : {"a": "", "q" : "Do you have a chunking strategy yet? If so, explain."}
    });
    */
  
    // notice the lack of S at the end
    const setVectorSizingQuestion = (questionId, answer) => {
      setVectorSizingQuestions(prevQuestions => ({
        ...prevQuestions,
        [questionId]: { "q" : prevQuestions[questionId]["q"], "a" : answer }
      }));
    };
  

  // This is the user used to invoke the functions
  const [appUser, setAppUser] = useState(null);



  useEffect(() => {
    if (card === "specialist") {
      setTitle("Specialist Team");      
    } else if (card === "maap-specialist") {
      setTitle("MAAP : MongoDB AI Applications Program");      
      setSCRequestVisibility(false)
      setSCProductVisibility(false)
      setScRequestType("MAAP")
    }

  }, []);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUser(null);
    } else {
      oktaAuth.getUser().then((info) => {
        //setUser(info);
        loginApiKey(info);
      });
    }
    
  }, [authState, oktaAuth]); // Update if authState changes

  // OLD VERSION WITHOUT SETTING ROLE AND DEPARTMENT FOR USER

  /* const loginApiKey = () => {
    // Getting the JWT token from Okta authState and passing as an paran to Realm
    const credentials = Realm.Credentials.jwt(authState.idToken?.idToken);

    app.logIn(credentials).then((appUser) => {
      setAppUser(appUser);
    });
  }; */

  const loginApiKey = (info) => {
    
    // Getting the JWT token from Okta authState and passing as an paran to Realm
    const credentials = Realm.Credentials.jwt(authState.idToken?.idToken);

    app.logIn(credentials).then((appUser) => {
      setAppUser(appUser);
      appUser.functions.get_user_role_team({user:info.email})
        .then((userInfo) => {
          
          info.title = userInfo.result.title
          info.department = userInfo.result.department

          setUser(info)
        })
      
    });
  };



  /* Validation
  Determine if a field has a valid value.
  Fail validation if not

  Build up an array of Validation Errors to display to the user upon validation failure

  */
  const addValidationError = (errorText) => {
    setTicketValidation((prevState) => [...prevState, errorText]);
  };

  const validation = (): boolean => {

    setTicketValidation([]);
    let validationPassed = true;

    if (subjectValue === "") {
      addValidationError("Subject is a required field");
      validationPassed = false;
    }

    // No validation if we are in Feedback mode for MAAP
    if (card=="maap-specialist" && scProductArea=="MAAP Feedback") {
      // At least one question answered
      if ((Object.values(maapFeedbackQuestions).every(item => {return item.a.length === 0;}))) {
        validationPassed = false;
        addValidationError("Please answer at least one question");
      }

      return validationPassed
    }

    if (accountFieldRequired && (accountInfo === "" || accountInfo == null)) {
      validationPassed = false;
      addValidationError("Account Name is a required field");
    }

    if (!(scProductArea === "MAAP-no-opp") && opportunityFieldRequired && (selectedOpp === "" || selectedOpp === null)) {
      validationPassed = false;
      addValidationError("Salesforce Opportunity Name is a required field");
    }


    if (descriptionValue === "") {
      addValidationError("Description is a required field");
      validationPassed = false;
    }

    if (primarySAValue === "" || primarySAValue === null) {
      validationPassed = false;
      addValidationError("Primary SA is a required field");
    }

    if (scProductArea === "") {
      validationPassed = false;
      addValidationError("SC-Product Area is a required field");
    }

    if (scRequestType === "") {
      validationPassed = false;
      addValidationError("SC-Request Type is a required field");
    }
    
    if (timezoneValue === "") {
      validationPassed = false;
      addValidationError("Timezone is a required field");
    }

    // End validation here for Specialist 
    if (card=="specialist") return validationPassed


    // MAAP Engagement questions
    // Define the questions to validate based on the condition
    let questionsToTest = [];
    
    if (scProductArea === "MAAP") {
      questionsToTest = [4, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    } else if (scProductArea === "MAAP-no-opp") {
      questionsToTest = [4, 5, 13];
    }
    
    // Check if any of the specified questions have been answered
    if (questionsToTest.every(q => maapEngagementQuestions[q]?.a.length === 0)) {
      validationPassed = false;
      addValidationError("Please answer at least one question");
    }
    
    return validationPassed;
  };


  /* 
    Build up body of API call to Atlas App Services, which then in turn calls Zendesk
  */

  const create_ticket = async () => {
    if (user === undefined) return;

    if (validation() === false) {
      setValidationToastOpen(true);
      return;
    } else {
      setValidationToastOpen(false);
      setWarningToastOpen(false);
      setSuccessToastOpen(false);
      setProgressToastOpen(true);
      setSubmitButtonDisabled(true)


      //retrieve the emails for CC field
      let CCfield = [];
      extraEmailsValue.forEach((e) => {
        CCfield.push({ user_email: emailCache[e] });
      });
      
      
      let subject = `${accountInfo.replaceAll("^","")} – ${subjectValue}`
      if (card === "maap-specialist") subject = `🗺️ MAAP | ${accountInfo.replaceAll("^","")} – ${subjectValue}`
      
      let ticket = {
        subject: subject,
        description: descriptionValue,
        brand: "Sales",
        formType: "SC Specialist",
        requester: {
          name: `${user.name}`,
          email: `${user.email}`,
        },
        "Your Sales Segment": salesSegment,
        group: "SO-SC-Specialist",
        CC: CCfield,
        "Customer name": accountInfo.replaceAll("^",""),
        
      };

      // Add answered sizing-search question to the description
      if (scRequestType=="Sizing" && scProductArea=="Search") {
        let atLeastOne = false;
        ticket["description"] = descriptionValue + "\n\n#### Search Sizing questionnaire:\n"
        Object.values(searchSizingQuestions).forEach(question => {
          if (question.a !== "") {
            atLeastOne = true;
            ticket["description"] += "~ " + question.q + "\n" + question.a + "\n\n" 
          }
        });
        if (!atLeastOne) {ticket["description"] += "~ none answered"}
      }
      // Add answered sizing-vector question to the description
      if (scRequestType=="Sizing" && scProductArea=="Vector Search") {
        let atLeastOne = false;
        ticket["description"] = descriptionValue + "\n\n#### Vector Search Sizing questionnaire:\n"
        Object.values(vectorSizingQuestions).forEach(question => {
          if (question.a !== "") {
            atLeastOne = true;
            ticket["description"] += "~ " + question.q + "\n" + question.a + "\n\n" 
          }
        });
      }
      // Add answered sizing-search question to the description
      if (scProductArea=="Realm-Migration") {
        let atLeastOne = false;
        ticket["description"] = descriptionValue + "\n\n#### Search Sizing questionnaire:\n"
        Object.values(realmMigrationSizingQuestion).forEach(question => {
          if (question.a !== "") {
            atLeastOne = true;
            ticket["description"] += "~ " + question.q + "\n" + question.a + "\n\n" 
          }
        });
        if (!atLeastOne) {ticket["description"] += "~ none answered"}
      }

      // MAAP - Engagement questions
      if ((card === "maap-specialist" && scProductArea === "MAAP") || scProductArea === "MAAP-no-opp") {
        let questionsToTest = [];
      
        // Determine the questions to test based on scProductArea
        if (scProductArea === "MAAP") {
          questionsToTest = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
        } else if (scProductArea === "MAAP-no-opp") {
          questionsToTest = [4, 5, 13, 15];
          ticket["description"] = descriptionValue + "\n## MAAP request without SFDC Opp\n";
        }
      
        // Prepare ticket description
        ticket["description"] = descriptionValue + "\n\n#### MAAP survey:\n";
      
        // Iterate over the relevant questions
        questionsToTest.forEach(questionIndex => {
          const question = maapEngagementQuestions[questionIndex];
      
          if (question) {
            // Handle the answer based on its type
            let answer = "";
            if (Array.isArray(question.a)) {
              // Join array values for checkbox responses
              answer = question.a.join(", ");
            } else if (typeof question.a === "string") {
              // Trim text-based responses
              answer = question.a.trim();
            }
      
            // Add question and answer to the ticket description
            ticket["description"] += `~ ${question.q}\n${answer || "/Not answered/"}\n\n`;
    
          }
        });
    
      }

      // MAAP - Feedback questions
      if (card === "maap-specialist" && scProductArea=="MAAP Feedback") {
        let atLeastOne = false;
        ticket["description"] = descriptionValue + "\n\n#### MAAP Feedback questions:\n"
        Object.values(maapFeedbackQuestions).forEach(question => {

          if (question.a !== "") {
            ticket["description"] += "~ " + question.q + "\n" + question.a + "\n\n" 
          }
          
        });
      }

      if (selectedOpp === "") {
      } else {
        // Retrieve the opp id from the list of opportunities
        let opp = oppsList.find((op) => op.nm === selectedOpp);
        if (Array.isArray(opp)) opp = opp[0];
        ticket["SFDC Opportunity link"] = "https://mongodb.lightning.force.com/lightning/r/Account/" + opp._id + "/view";
      }

      /* ticket["Requester's Region"] = requesterRegion */
      ticket["Priority"] = priority
      ticket["SC-Product Area"] = scProductArea
      ticket["SC-Request Type"] = scRequestType.replace("-no-opp", "")
      ticket["Timezone (SA)"] = timezoneValue
      ticket["Account SA Email"] = saCache[primarySAValue]
      ticket["CC"].push( {user_email : saCache[primarySAValue] } ) // Put the Primary SA email in CC

      // Call the Realm Function "create_zendesk_ticket"
      const result = await appUser.functions.create_zendesk_ticket(ticket);


      if (result.status === 200) {
        setProgressToastOpen(false);
        setSuccessToastOpen(true); // set state to true on success
        setTicketID(
          `https://help-sales.mongodb.com/hc/en-us/requests/${result.ticketId}`
        );
        clear_form()
        setSubmitButtonDisabled(false)
      } else {
        setProgressToastOpen(false);
        setWarningToastOpen(true);
        const errorBody = result.zendeskResponse.body;
        const jsonObject = JSON.parse(errorBody);

        setTicketWarning(`Error - ${jsonObject.details.base[0].description}`);
        setSubmitButtonDisabled(false)
      }
    }
  };

  

  const searchAccount = async (event: any) => {
    if (isAccountSelected) {
      // Reset the flag once we've ignored one change.
      setIsAccountSelected(false);
      return;
    }

    const searchQuery = event.target.value;

    if (user === undefined) return;

    setAccountInfo(searchQuery);

    if (searchQuery.length < 3) {
      setAccountList([]);
      return;
    }

    let search = { searchTerm: searchQuery };

    const response = await appUser.functions.search_account(search);

    setAccountList(response.result);

    //clean the opp field
    setSelectedOpp("")
  };

  const selectAccount = async (acct: any) => {
    setIsAccountSelected(true);

    let search = { account_id: acct._id };

    const response = await appUser.functions.search_opp(search);

    setOppsList(response.result);
    setDisableOpp(false);

    setAccountInfo(acct.nm);
    setAccountId(acct._id);
  };

  

  const searchEmail = async (searchQuery: any) => {

    if (user === undefined) return;

    if (searchQuery.length < 3) {
      setEmailList([]);
      return;
    }

    let search = { searchTerm: searchQuery, title: "noSpecialist" };

    const response = await appUser.functions.search_by_email(search);

    let emails = [];

    // All this stuff here is done to show the name and surname in the CC field
    // And to be able to easily retrieve the email, when the user press Submit
    response.result.forEach((email) => {
      const concatName = `${email.firstName} ${email.lastName}`;
      emails.push(concatName);
      let cacheObject = emailCache;
      cacheObject[concatName] = email.username;
      setEmailCache(cacheObject);
    });

    setEmailList(emails);
  };

  
  const handleExtraEmailField = (e) => {
    setExtraEmailsValue(e);
    setEmailList(e);
  };



  const searchPrimarySA = async (searchQuery: any) => {
    if (user === undefined) return;

    if (searchQuery.length < 3) {
      setPrimarySAList([]);
      return;
    }

    let search = { searchTerm: searchQuery }; // pass the title parameter to search only the SAs


    const response = await appUser.functions.search_by_email(search);

    let emails = [];

    response.result.forEach((email) => {
      const concatName = `${email.firstName} ${email.lastName}`;
      emails.push(concatName);
      let cacheObject = saCache;
      cacheObject[concatName] = email.username;
      setSaCache(cacheObject);
    });

    

    setPrimarySAList(emails);
  };


  const clear_form = async () => {

  setOppsList([])
  setSelectedOpp("")
  setDisableOpp(true)
  setAccountId("")
  setAccountInfo("")
  setAccountList([])
  setSubjectValue("")
  setDescriptionValue("")
  setScProductAreaWrap("")
  setExtraEmailsValue([])
  setEmailList([])
  setEmailCache({})
  setPrimarySAList([])
  setPrimarySAValue("")
  setSaCache({})
    
  }


  return (

    <Layout>
      <div>

        {
          window.location.href.includes("noLogo") ?  null : // Hide if using the special url
          (
            <>
              <Row className="form-hero-image">
                <Col>
                  <H1 className="form-hero-text">{title}</H1>
                </Col>
              </Row>

              <Row className="navigationRow">
                <Col></Col>
                <Col xs={12} md={10} lg={10}>
                  <Body className="navigationStyle">
                    <a className="navigationRefStyle" href="/">
                      Home
                    </a>
                    <span> &#62; </span> {title}
                  </Body>
                </Col>
                <Col></Col>
              </Row>
            </>

          )
        }

        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            <div>
              
            { !SCProductVisibility ? 
            <div>
              <Label htmlFor="initialDesc" className="initial">
              This form is designed to submit questions regarding MAAP, engagement requests from the field, and feedback for MAAP.
              <br />
              <br />
              Questions may be in regards to a customer you have that is a good fit for MAAP, has shown interest in MAAP, or is already in early stages of MAAP implementation and you are ready to engage with a MAAP Partner. 
              <br />
              <br />
              The MongoDB dedicated MAAP team, made up of cross-functional DRIs, will get back to you within 2 business days. 
              </Label>
              <br />
              <br />
              <Label className="fieldMargin" htmlFor="scProduct">
              How can we help?
              </Label>
              <RadioBoxGroup 
                className="radio-box-group-style"
                onChange={(selectedValue) => setScProductArea(selectedValue.target.value)}>
                <RadioBox value="MAAP">I have an active opportunity and need AI expertise</RadioBox>
                <RadioBox value="MAAP-no-opp">I do not have an opportunity but need AI expertise</RadioBox>
                <RadioBox value="MAAP Feedback">Provide feedback</RadioBox>
              </RadioBoxGroup>
              <br />
              <hr />
              <br />
            </div> 
            : null   }

            { (card === "specialist" ) ? <div> 


              <Label className="fieldMargin" htmlFor="accountName">
                {accountFieldLabel}
              </Label>
              <SearchInput
                id="accountName"
                className="fieldMargin"
                value={accountInfo}
                onChange={(event) => searchAccount(event)}
                aria-label="Account Name"
              >
                {accountList.map((acct: any, index) => {
                  return (
                    <SearchResult
                      onClick={() => selectAccount(acct)}
                      key={index}
                    >
                      {acct.nm} - {acct.owner}
                    </SearchResult>
                  );
                })}
              </SearchInput>

              <Combobox
                id="searchOpp"
                className="fieldMargin"
                disabled={disableOpp}
                label={opportunityFieldLabel}
                onChange={(value) => setSelectedOpp(value)}
                value={selectedOpp} // set the current value
              >
                {oppsList.map((opp: any, index) => {
                  return <ComboboxOption key={index} value={opp.nm} />;
                })}
              </Combobox>
              
              <TextInput
                className="fieldMargin"
                baseFontSize={13}
                label="Subject *"
                value={subjectValue}
                onChange={(event) => setSubjectValue(event.target.value)}
                optional={false}
              />
              <Label id="description-label" className="fieldMargin" htmlFor='descriptionTextArea'>
                Description *
              </Label>
             
                <Description>
                Detailed description of opportunity and engagement (sales challenges, customer challenges, unique considerations). 
                <br></br>Please add links as applicable (web links to 3 Whys/NBM deck, discovery capture sheet, Atlas sizing/pricing info, etc.)
                Note that if more information is provided, engagement completion is faster.
                <br></br>
                If documents are included in this request, make sure to add the <i>sc-specialists-team</i> distro to the permissions with Edit or Comment access.
                </Description>
              
              <TextareaAutosize
                id='descriptionTextArea'
                className="fieldMargin"
                minRows={12}
                value={descriptionValue}
                onChange={(event) => setDescriptionValue(event.target.value)}
              />
              { SCProductVisibility ? 
              <Select
                className="fieldMargin"
                label="Product Area *"
                name="SC-Product Area"
                baseFontSize={13}
                onChange={(selectedValue) => setScProductAreaWrap(selectedValue)}
              >
                <Option value="CloudManager">CloudManager</Option>
                <Option value="Kubernetes">Kubernetes</Option>
                <Option value="OpsManager">OpsManager</Option>
                <Option value="Realm">Edge</Option>
                <Option value="Realm">Realm</Option>
                <Option value="Realm Migration">Realm Migration</Option>
                <Option value="Search">Search</Option>
                <Option value="Streaming">Streaming</Option>
                <Option value="Vector Search">Vector Search</Option>
              </Select>
              : null }

              { (scProductArea=="Realm"  ) ? <Banner variant="danger" className="bannerStyle fieldMargin">
                  MongoDB has internally announced the internal End of Life of Realm, Atlas Device Sync, and Atlas for the Edge. 
                  <br /><br />
                  More details will follow in the coming weeks. In the mean time, please do not pitch the product to customers. If you have an existing customer that needs help or needs help with migration solutions, please change the drop down above to 'Realm Migration' and we will assist.<br />         
              
              </Banner> : ""}
              { (scProductArea=="Realm Migration" ) ? <Banner variant="info" className="bannerStyle fieldMargin">
                MongoDB has internally announced the internal End of Life of Realm, Atlas Device Sync, and Atlas for the Edge. 
                <br /><br />
                More details will follow in the coming weeks. In the mean time, please do not pitch the product to customers. We will assist with walking you and customers through EOL and EOS options.   
              </Banner> : ""}

              { (["OpsManager","Kubernetes","CloudManager"].includes(scProductArea)) ? <Banner className="bannerStyle fieldMargin">
                      Please, note that the team does not provide generic "MongoDB Enterprise Advanced" support.<br />
                      Requests should be specifically for deeper technical presales support on {scProductArea}, not just generic EA.
              </Banner> : ""}
              
              { SCRequestVisibility ?
              <Select
                className="fieldMargin"
                label="Request Type *"
                disabled={realmException}
                name="SC-Request Type"
                baseFontSize={13}
                onChange={(selectedValue) => setScRequestType(selectedValue)}
              >
                <Option value="Discovery">Discovery</Option>
                <Option value="Escalation">Escalation</Option>
                <Option value="Internal Task">Internal Task</Option>
                <Option value="MS0 Support">MS0 Support</Option>
                <Option value="Partner Support">Partner Support</Option>
                <Option value="POC">POC</Option>
                <Option value="Post-Sale Activity">Post-Sale Activity</Option>
                <Option value="Pre-Onboarding Support">Pre-Onboarding Support</Option>
                <Option value="Question">Question</Option>
                <Option value="Sizing">Sizing</Option>
                <Option value="Training">Training</Option>
                <Option value="Workshop">Workshop</Option>
                <Option value="Other">Other</Option>
              </Select>
              : null }

              { (scRequestType=="Sizing" && scProductArea=="Search") ? <Banner className="bannerStyle fieldMargin">
                  <a
                    href="https://specialists.sa.prod.corp.mongodb.com/Search/SizingCalculator"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link to our Sizing Calculator
                  </a><br />
                      {Object.entries(searchSizingQuestions).map(([key, question]) => (
                        <TextInput
                          key={key}
                          className="fieldMargin"
                          baseFontSize={13}
                          label=' ' 
                          optional={false}
                          description={question.q}
                          value={question.a}
                          onChange={(event) => setSearchSizingQuestion(Number(key), event.target.value)}
                        />
                      ))}
              </Banner> : ""}
              { (scRequestType=="Sizing" && scProductArea=="Vector Search") ? <Banner className="bannerStyle fieldMargin">
                  <a
                    href="https://specialists.sa.prod.corp.mongodb.com/Search/SizingCalculator"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link to our Sizing Calculator
                  </a><br />
                      {Object.entries(vectorSizingQuestions).map(([key, question]) => (
                        <TextInput
                          key={key}
                          className="fieldMargin"
                          baseFontSize={13}
                          label=' ' 
                          optional={false}
                          description={question.q}
                          value={question.a}
                          onChange={(event) => setVectorSizingQuestion(Number(key), event.target.value)}
                        />
                      ))}
              </Banner> : ""}
              { (scProductArea=="Realm Migration") ? <Banner className="bannerStyle fieldMargin">
                      Please assist us by answering as many questions as you can.<br />
                      {Object.entries(realmMigrationSizingQuestion).map(([key, question]) => (
                        <TextInput
                          key={key}
                          className="fieldMargin"
                          baseFontSize={13}
                          label=' ' 
                          optional={true}
                          description={question.q}
                          value={question.a}
                          onChange={(event) => setRealmMigrationSizingQuestion(Number(key), event.target.value)}
                        />
                      ))}
              </Banner> : ""}
              
              <Combobox
                label="Primary SA *" // In zendesk the field is called Account SA Email
                placeholder="Search SA"
                disabled={realmException}
                onFilter={(event) => searchPrimarySA(event)}
                onChange={(e) => setPrimarySAValue(e)}
                multiselect={false}
                >
                {primarySAList.map((email: any, index) => {
                  return <ComboboxOption key={index} value={email} />;
                })}
              </Combobox>
              <br />

              <Select
                className="fieldMargin"
                label="Timezone *"
                name="timezone"
                disabled={realmException}
                baseFontSize={13}
                onChange={(selectedValue) => setTimezoneValue(selectedValue)}
              >
                <Option value="America/Los_Angeles">America/Los_Angeles</Option>
                <Option value="America/Chicago">America/Chicago</Option>
                <Option value="America/New_York">America/New_York</Option>
                <Option value="Europe/London">Europe/London</Option>
                <Option value="Europe/Berlin">Europe/Berlin</Option>
                <Option value="Asia/Kolkata">Asia/Kolkata</Option>
                <Option value="Asia/Singapore">Asia/Singapore</Option>
                <Option value="Australia/Sydney">Australia/Sydney</Option>
              </Select>

              <Combobox
                label="CC"
                placeholder="Search"
                disabled={realmException}
                onFilter={(event) => searchEmail(event)}
                onChange={(e) => handleExtraEmailField(e)}
                multiselect={true}
              >
                {emailList.map((email: any, index) => {
                  return <ComboboxOption key={index} value={email} />;
                })}
              </Combobox>
              
              <br/>
              
              
              </div> : null }
            
            { (card !== "specialist" && scProductArea === "") ? <div className="larger"></div> : null }

            { (scProductArea === "MAAP") ? <div> 

            <h4>General</h4>

            <TextInput
              className="fieldMargin"
              baseFontSize={13}
              label="Subject *"
              value={subjectValue}
              onChange={(event) => setSubjectValue(event.target.value)}
              optional={false}
            />

            <Label className="fieldMargin" htmlFor="accountName">
              {accountFieldLabel}
            </Label>
            <SearchInput
              id="accountName"
              className="fieldMargin"
              value={accountInfo}
              onChange={(event) => searchAccount(event)}
              aria-label="Account Name"
            >
              {accountList.map((acct: any, index) => {
                return (
                  <SearchResult
                    onClick={() => selectAccount(acct)}
                    key={index}
                  >
                    {acct.nm} - {acct.owner}
                  </SearchResult>
                );
              })}
            </SearchInput>

            <Combobox
              id="searchOpp"
              className="fieldMargin"
              disabled={disableOpp}
              label={opportunityFieldLabel}
              onChange={(value) => setSelectedOpp(value)}
              value={selectedOpp} // set the current value
            >
              {oppsList.map((opp: any, index) => {
                return <ComboboxOption key={index} value={opp.nm} />;
              })}
            </Combobox>

            <Label id="description-label" className="fieldMargin" htmlFor='descriptionTextArea'>
             Additional Salesforce Opportunities (paste all applicable links) *
            </Label>

            <TextareaAutosize
              id='descriptionTextArea'
              className="fieldMargin"
              minRows={2}
              value={descriptionValue}
              onChange={(event) => setDescriptionValue(event.target.value)}
            />
            
            <br/>
            <br/>
            <br />
            <h4>AI Technical Discovery</h4>
              <Label id="q4-label" className="fieldMargin" htmlFor='descriptionTextArea'>{maapEngagementQuestions[4].q}</Label>
              <MultiSelect
                isMulti
                name="maap-engagement-4"
                options={maapEngagementQuestions[4].checkboxs}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(event) => handleMultiChange(4, event)}
              />
           
              <br />
              <Label id="q7-label" className="fieldMargin" htmlFor='descriptionTextArea'>{maapEngagementQuestions[7].q}</Label>
              <TextInput
                  key={2}
                  className="fieldMargin"
                  placeholder="Yes, storing document embeddings for semantic search"
                  baseFontSize={13}
                  label=' ' 
                  optional={false}
                  value={maapEngagementQuestions[7].a}
                  onChange={(event) => setMaapEngagementQuestion(7, event.target.value)}
                />
              <Label id="q8-label" className="fieldMargin" htmlFor='descriptionTextArea'>{maapEngagementQuestions[8].q}</Label>
              <Select
                className="fieldMargin"
                label=""
                name="maap-engagement-3"
                baseFontSize={13}
                onChange={(selectedValue) => setMaapEngagementQuestion(8, selectedValue)}
              >
                <Option>In Atlas</Option>
                <Option>In MongoDB EA or Community</Option>
                <Option>In other databases or systems</Option>
              </Select> 

              <br />
              <br />
            <h4>PS Discovery</h4>

            <Label id="q9-label" className="fieldMargin" htmlFor='descriptionTextArea'>{maapEngagementQuestions[9].q}</Label>
              <TextInput
                  key={2}
                  className="fieldMargin"
                  placeholder="Some guidance and code examples"
                  baseFontSize={13}
                  label=' ' 
                  optional={false}
                  value={maapEngagementQuestions[9].a}
                  onChange={(event) => setMaapEngagementQuestion(9, event.target.value)}
                />

              <br />
              <br />
            <h4>Command of the Message</h4>
            <Label id="q10-label" className="fieldMargin" htmlFor='descriptionTextArea'>{maapEngagementQuestions[10].q}</Label>
              <TextInput
                  key={2}
                  className="fieldMargin"
                  placeholder="Building a legal document analyzer for law firms to extract key contract terms"
                  baseFontSize={13}
                  label=' ' 
                  optional={false}
                  value={maapEngagementQuestions[10].a}
                  onChange={(event) => setMaapEngagementQuestion(10, event.target.value)}
                />

            <Label id="q11-label" className="fieldMargin" htmlFor='descriptionTextArea'>{maapEngagementQuestions[11].q}</Label>
              <TextInput
                  key={2}
                  className="fieldMargin"
                  placeholder="Legal Tech team, driven by need to reduce manual document review time"
                  baseFontSize={13}
                  label=' ' 
                  optional={false}
                  value={maapEngagementQuestions[11].a}
                  onChange={(event) => setMaapEngagementQuestion(11, event.target.value)}
                />
            <Label id="q12-label" className="fieldMargin" htmlFor='descriptionTextArea'>{maapEngagementQuestions[12].q}</Label>
              <TextInput
                  key={2}
                  className="fieldMargin"
                  baseFontSize={13}
                  label=' ' 
                  optional={false}
                  value={maapEngagementQuestions[12].a}
                  onChange={(event) => setMaapEngagementQuestion(12, event.target.value)}
                />

            <Label id="q13-label" className="fieldMargin" htmlFor='descriptionTextArea'>{maapEngagementQuestions[13].q}</Label>
              <TextInput
                  key={2}
                  className="fieldMargin"
                  baseFontSize={13}
                  label=' ' 
                  optional={false}
                  value={maapEngagementQuestions[13].a}
                  onChange={(event) => setMaapEngagementQuestion(13, event.target.value)}
                />

            <Label id="q14-label" className="fieldMargin" htmlFor='descriptionTextArea'>{maapEngagementQuestions[14].q}</Label>
              <TextInput
                  key={2}
                  className="fieldMargin"
                  baseFontSize={13}
                  label=' ' 
                  optional={false}
                  value={maapEngagementQuestions[14].a}
                  onChange={(event) => setMaapEngagementQuestion(14, event.target.value)}
                />

            <br />
            <br />
            <Combobox
              className="fieldMargin"
              label="Primary SA *" // In zendesk the field is called Account SA Email
              placeholder="Search SA"
              disabled={realmException}
              onFilter={(event) => searchPrimarySA(event)}
              onChange={(e) => setPrimarySAValue(e)}
              multiselect={false}
              >
              {primarySAList.map((email: any, index) => {
                return <ComboboxOption key={index} value={email} />;
              })}
            </Combobox>

            <Select
              className="fieldMargin"
              label="Timezone *"
              name="timezone"
              disabled={realmException}
              baseFontSize={13}
              onChange={(selectedValue) => setTimezoneValue(selectedValue)}
            >
              <Option value="America/Los_Angeles">America/Los_Angeles</Option>
              <Option value="America/Chicago">America/Chicago</Option>
              <Option value="America/New_York">America/New_York</Option>
              <Option value="Europe/London">Europe/London</Option>
              <Option value="Europe/Berlin">Europe/Berlin</Option>
              <Option value="Asia/Kolkata">Asia/Kolkata</Option>
              <Option value="Asia/Singapore">Asia/Singapore</Option>
              <Option value="Australia/Sydney">Australia/Sydney</Option>
            </Select>

            <Combobox
              label="CC"
              placeholder="Search"
              disabled={realmException}
              onFilter={(event) => searchEmail(event)}
              onChange={(e) => handleExtraEmailField(e)}
              multiselect={true}
            >
              {emailList.map((email: any, index) => {
                return <ComboboxOption key={index} value={email} />;
              })}
            </Combobox>

            <br/>
              </div> : null }

            { (scProductArea === "MAAP-no-opp") ? <div> 

              <h4>General</h4>

              <TextInput
                className="fieldMargin"
                baseFontSize={13}
                label="Subject *"
                value={subjectValue}
                onChange={(event) => setSubjectValue(event.target.value)}
                optional={false}
              />

              <Label className="fieldMargin" htmlFor="accountName">
                {accountFieldLabel}
              </Label>
              <SearchInput
                id="accountName"
                className="fieldMargin"
                value={accountInfo}
                onChange={(event) => searchAccount(event)}
                aria-label="Account Name"
              >
                {accountList.map((acct: any, index) => {
                  return (
                    <SearchResult
                      onClick={() => selectAccount(acct)}
                      key={index}
                    >
                      {acct.nm} - {acct.owner}
                    </SearchResult>
                  );
                })}
              </SearchInput>

              <Label id="description-label" className="fieldMargin" htmlFor='descriptionTextArea'>
              Please provide general context on how the MAAP CoE can be most helpful *
              </Label>

            <TextareaAutosize
              id='descriptionTextArea'
              className="fieldMargin"
              minRows={2}
              value={descriptionValue}
              onChange={(event) => setDescriptionValue(event.target.value)}
            />

              <br/>
              <br/>     
              <h4>AI Technical Partner</h4>
              <Label id="q4-label" className="fieldMargin" htmlFor='descriptionTextArea'>{maapEngagementQuestions[4].q}</Label>
              <MultiSelect
                isMulti
                name="maap-engagement-4"
                options={maapEngagementQuestions[4].checkboxs}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(event) => handleMultiChange(4, event)}
              />
              <br/>
              <br/>
              <h4>Discovery Questions</h4>
                <Label id="q13-label" className="fieldMargin" htmlFor='descriptionTextArea'>{maapEngagementQuestions[13].q}</Label>
                <TextInput
                    key={2}
                    className="fieldMargin"
                    baseFontSize={13}
                    label=' ' 
                    optional={false}
                    value={maapEngagementQuestions[13].a}
                    onChange={(event) => setMaapEngagementQuestion(13, event.target.value)}
                  />


                <br />
              <br />
              <Combobox
                className="fieldMargin"
                label="Primary SA *" // In zendesk the field is called Account SA Email
                placeholder="Search SA"
                disabled={realmException}
                onFilter={(event) => searchPrimarySA(event)}
                onChange={(e) => setPrimarySAValue(e)}
                multiselect={false}
                >
                {primarySAList.map((email: any, index) => {
                  return <ComboboxOption key={index} value={email} />;
                })}
              </Combobox>

              <Select
                className="fieldMargin"
                label="Timezone *"
                name="timezone"
                disabled={realmException}
                baseFontSize={13}
                onChange={(selectedValue) => setTimezoneValue(selectedValue)}
              >
                <Option value="America/Los_Angeles">America/Los_Angeles</Option>
                <Option value="America/Chicago">America/Chicago</Option>
                <Option value="America/New_York">America/New_York</Option>
                <Option value="Europe/London">Europe/London</Option>
                <Option value="Europe/Berlin">Europe/Berlin</Option>
                <Option value="Asia/Kolkata">Asia/Kolkata</Option>
                <Option value="Asia/Singapore">Asia/Singapore</Option>
                <Option value="Australia/Sydney">Australia/Sydney</Option>
              </Select>

              <Combobox
                label="CC"
                placeholder="Search"
                disabled={realmException}
                onFilter={(event) => searchEmail(event)}
                onChange={(e) => handleExtraEmailField(e)}
                multiselect={true}
              >
                {emailList.map((email: any, index) => {
                  return <ComboboxOption key={index} value={email} />;
                })}
              </Combobox>

              <br/>
                </div> : null }

            { (scProductArea=="MAAP Feedback") ? <div>
                <TextInput
                  className="fieldMargin"
                  baseFontSize={13}
                  label="Subject *"
                  value={subjectValue}
                  onChange={(event) => setSubjectValue(event.target.value)}
                  optional={false}
                />
                <br />

                {Object.entries(maapFeedbackQuestions).map(([key, question]) => (
                <div>

                  <Label htmlFor={key}>
                  {question.qq}
                  </Label>
                  <br />
                  <Label htmlFor={key} className="initial">
                  {question.q}
                  </Label>
                  <TextareaAutosize
                    id="descriptionTextArea"
                    className="fieldMargin"
                    minRows={5}
                    value={question.a}
                    onChange={(event) => setMaapFeedbackQuestion(Number(key), event.target.value)}
                  />
                  <br />
                  <br />
                  </div>
                ))}
              
              </div> : null } 

              <FormFooter
                onCancel={() => {
                  navigate(`/`);
                }}
                primaryButton={{
                  text: "Submit",
                  disabled: submitButtonDisabled,
                  onClick: () => create_ticket(),
                }}
              />
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Toast
          variant="warning"
          title="Validation Error"
          body={
            <>
              {ticketValidation.map((error, index) => (
                <React.Fragment key={index}>
                  * <a>{error}</a>
                  {index !== ticketValidation.length - 1 && <br />}
                </React.Fragment>
              ))}
            </>
          }
          open={validationToastOpen}
          close={() => setValidationToastOpen(false)}
        />
        <Toast
          variant="progress"
          title="Creating ticket"
          body="Sending your ticket information to Specialist Team"
          open={progressToastOpen}
          close={() => setProgressToastOpen(false)}
        />
        <Toast
          variant="success"
          title="Ticket created successfully"
          body={
            <a href={ticketId} target="_blank" rel="noopener noreferrer">
              Click to open ticket
            </a>
          }
          open={successToastOpen}
          close={() => setSuccessToastOpen(false)}
        />
        <Toast
          variant="warning"
          title="Error in ticket creation"
          body={ticketWarning}
          open={warningToastOpen}
          close={() => setWarningToastOpen(false)}
        />
      </div>
    </Layout>

  )
};
