// RunbookSelector.tsx// RunbookSelector.tsx
import React, { useEffect } from 'react';
import { Option, Select, OptionGroup } from "@leafygreen-ui/select";
import Banner from "@leafygreen-ui/banner";
import { Label, Description } from "@leafygreen-ui/typography";
import EngagementsPrerequisites from './EngagementsPrerequisites'; // Import the new component

interface RunbookSelectorProps {
  selectedEngagement: string;
  setRunbook: (value: string) => void;
  runbook: string;
  registerValidation: (key: string, validateFunc: () => ValidationError) => void;
  unregisterValidation: (key: string) => void;
  setPostDescription: (description: string) => void;
}

interface ValidationError {
  validation: boolean;
  message: string;
}

// ------------------------------------------------------------------------
// ------ Runbook Description
// ------------------------------------------------------------------------

const runbookDescriptions: { [key: string]: string } = {
  "Metric Review": "Analyzing the performance and health of our customers’ Atlas clusters: Identify and explain any issues while providing actionable recommendations. This session should not be used as a discovery call. By conducting a deep dive into metrics using all the tools available to Solutions Architects, we can uncover most potential issues and offer tailored advice. In scenarios where no specific root cause is identified, we will share our understanding with the customer and position Professional Services (PS) for hands-on improvements.",
  "Backup Optimization": "Evaluating and recommending adjustments to backup policies: We aim to help customers manage their backup costs while gaining a clear understanding of the cost drivers. Our analysis highlights the value of each backup option and is discussed with the customer to ensure it aligns with their needs. Since potential solutions may require changes to cluster settings and architecture, these conversations must involve a technical team member.",
  "Cluster Optimization": "Provide a comprehensive analysis of the findings and recommendations from running the mSizer script on MongoDB clusters. This should include a detailed evaluation of the current cluster workload, resource utilization, and opportunities for optimization.",
  "Technical Success Plan": "Validate technical goals, sizing, architectural composition, pre-onboarding checklist, timelines and milestones for go-lives, comprehensive project planning, and disaster recovery planning.",
  "Support Go-Live Lookback": "This meeting is designed to conclude the Migration phase, following our Post-Go Live support engagement: During this session, we will present the outcomes of our interventions, address any remaining blockers, highlight the positive impact of the MongoDB Account team’s efforts.",
  "Support Go-Live": "An ideal engagement to position early in the Atlas NWL migration process: This engagement focuses on validating the customer’s technical team’s understanding of Atlas, ensuring they can successfully implement the recommended configuration and architecture. For very large dedicated WL, we go further by reviewing the customer’s migration plan, identifying opportunities for additional Atlas services, and ensuring they are fully equipped to manage their new production workloads effectively.",
  "Pre-Prod Design Validation" : "For Customers with an existing MongoDB Schema, during that 1h-session we will identify potential performance issues or bottlenecks that could emerge once they move to production receiving a higher workload.",
  "Design Review" : "For Premier Opportunity only! We guide the customer during a whiteboard session on data modeling and provide expert advice on programmatically interacting with the data effectively.",
  "Customer Diagram Techstack Review" : "This runbook helps identify new NWLs by uncovering underutilized MongoDB/Atlas capabilities. Using the customer’s high-level architecture diagram as a foundation, this session facilitates a discovery discussion with our Champion to explore their current architecture and assess their interest in initiating PreSales conversations.",
  // Add more descriptions as needed
};

// ------------------------------------------------------------------------

const RunbookSelector: React.FC<RunbookSelectorProps> = ({ setPostDescription, selectedEngagement, runbook, setRunbook, registerValidation, unregisterValidation }) => {
  useEffect(() => {
    // Register and unregister `RunbookSelector`-specific validation, if needed.
    registerValidation("RunbookSelector", validateRunbookSelector);
    console.log("MOUNTING RunbookSelector");
    return () => { // Unmounting: clean & Reset runbook to an empty string on unmount
      unregisterValidation("RunbookSelector");
      console.log("Unmounting RunbookSelector");
      setRunbook(null); 
    };}, []);

  const validateRunbookSelector = (): ValidationError => {
    if (runbook === null) return { validation: false, message: "Please select a runbook." };
    return {validation: true, message: ""};
  };

  return (
    <>
      <Banner variant="warning" className="bannerStyle fieldMargin">
        <strong>Ongoing Pilot - Limited Access to Runbooks</strong><br /><br />
        Runbooks available only to the 34 Pilot members:
        <ul>
            <li>Technical Success Plan</li>
            <li>Go-live Support</li>
            <li>Post Go-live Lookback</li>
            <li>Atlas Metrics Review</li>
            <li>Backup Optimization</li>
            <li>Cluster Optimization</li>
            <li>Pre-Prod Design Validation</li>
            <li>Design Review</li>
            <li>Customer Diagram Techstack Review</li>
        </ul>
      </Banner> 

      <Label className="fieldMargin" id="description-label" htmlFor="descriptionTextArea">
        Runbook *
      </Label>
      <Description>
        Find detailed explanations of our engagements on{' '}
        <a
          href="https://wiki.corp.mongodb.com/display/10GEN/CS+x+RSC%3A+Runbook+Master+list"
          target="_blank"
          rel="noopener noreferrer"
        >
          this page
        </a>.
      </Description>
      <Select
        className="fieldMargin"
        label=""
        name="runbook"
        baseFontSize={13}
        onChange={(selectedValue) => setRunbook(selectedValue)}
      >
        <OptionGroup label="ONBOARDING">
        <Option value="Technical Success Plan">Technical Success Plan</Option>
        <Option value="Support Go-Live">Go-Live Support</Option>
        <Option value="Support Go-Live Lookback">Post Go-live Lookback</Option>
        <Option value="Design Review">Design Review</Option>
        <Option value="Pre-Prod Design Validation">Pre-Prod Design Validation</Option>
        </OptionGroup>
        <OptionGroup label="CONSUMPTION">
          <Option value="Metric Review">Atlas Metrics Review</Option>
          <Option value="Backup Optimization">Backup Optimization</Option>
          <Option value="Cluster Optimization">Cluster Optimization</Option>
          <Option value="Customer Diagram Techstack Review">Customer Diagram Techstack Review</Option>
        </OptionGroup>
      </Select>
      {runbook && (
        <>
          <Banner variant="info" className="bannerStyle fieldMargin">
            <strong>{runbook}</strong>
            <br />
            <br />
            {runbookDescriptions[runbook]}
          </Banner> {/*
          <EngagementsPrerequisites
            selectedRunbook={runbook}
            registerValidation={registerValidation}
            unregisterValidation={unregisterValidation}
            setPostDescription={setPostDescription}
          /> */}
        </>
      )}
    </>
  );
};

export default RunbookSelector;